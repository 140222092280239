import { useContext, useState } from 'react';
import Modal from 'components/base/BaseModal';
import { ModalContext } from 'context/ModalContext';
import { LinkButton } from 'components/UIComponents';
import BulkPodcastForm from 'components/forms/spots/BulkPodcastForm';

const EDIT_PODCAST_SPOTS_MODAL_NAME = 'editPodcastSpotsModal';

const EditPodcastSpotsAction = () => {
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    onModalOpen(EDIT_PODCAST_SPOTS_MODAL_NAME);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onModalClose(EDIT_PODCAST_SPOTS_MODAL_NAME);
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          name={EDIT_PODCAST_SPOTS_MODAL_NAME}
          title="Edit Podcast Spots"
          onModalClose={handleCloseModal}
        >
          <BulkPodcastForm onModalClose={handleCloseModal} />
        </Modal>
      )}
      <LinkButton onClick={handleOpenModal}>Edit</LinkButton>
    </>
  );
};

export default EditPodcastSpotsAction;