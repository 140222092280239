import { useState, useEffect, useCallback } from 'react';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';
import { LegacySpotAPI } from 'api/legacySpotAPI';

interface IVersion {
  id: number;
  event: string;
  created_at: string;
  object_changes: Record<string, [string | null, string | null]>;
  whodunnit: string;
}

const formatChangeLog = (version: IVersion): string | null => {
  const changes = version.object_changes;
  const user = version.whodunnit || 'Unknown';
  const timestamp = new Date(version.created_at).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).replace(',', ' at');

  const snakeToCapitalized = (str: string): string => {
    if (str.endsWith('_id')) {
      return str
        .replace('_id', '')
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    if (str.startsWith('is_')) {
      return str
        .replace('is_', '')
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if ('traffic_sent_at_date' in changes && changes.traffic_sent_at_date[1] !== null) {
    return `<b>${user}</b> sent Traffic ${timestamp}`;
  }
  if ('payment_id' in changes && changes.payment_id[1] !== null) {
    return `<b>${user}</b> created Payment ${timestamp}`;
  }
  if ('order_id' in changes && changes.order_id[1] !== null) {
    return `<b>${user}</b> sent Spot in Order ${timestamp}`;
  }

  const formatDateIfNeeded = (value: string | null) => {
    return value ? value.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1') : value;
  };

  const formattedChanges = Object.entries(changes)
    .map(([attribute, [oldValue, newValue]]) => {
      const label = snakeToCapitalized(attribute);

      const checkDate = (value: string | null) => (label === 'Start Date' || label === 'End Date') ?
        formatDateIfNeeded(value) : value;

      if (attribute === 'updated_at' || attribute === 'created_at' ||
        attribute === 'created_by_id' || attribute === 'updated_by_id' ||
        attribute === 'created_by' || attribute === 'updated_by') {
        return null;
      }
      if (oldValue === null && newValue !== null) {
        return `${label} set to '${checkDate(newValue)}'`;
      }
      if (oldValue !== null && newValue === null) {
        return `${label} cleared (was '${checkDate(oldValue)}')`;
      }
      if (oldValue !== null && newValue !== null) {
        return `${label} changed from '${checkDate(oldValue)}' to '${checkDate(newValue)}'`;
      }
      return null;
    })
    .filter(Boolean)
    .join(', ');

  if (!formattedChanges) return null;

  return `<b>${user}</b> updated: ${formattedChanges} ${timestamp}`;
};

export const usePodcastSpotVersions = (id: number, limit: number, spotType: string) => {
  const [versions, setVersions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstIncluded, setFirstIncluded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const fetchVersions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      let response;
      if(spotType === 'Podcast') {
        response = await PodcastSpotAPI.getVersions(page, limit, id);
      }
      else {
        response = await LegacySpotAPI.getVersions(page, limit, id);
      }

      const fetchedVersions: IVersion[] = response?.data?.data?.versions || [];
      if(response?.data?.data?.first_included === true) {
        setFirstIncluded(true);
      }

      const formattedVersions = fetchedVersions
        .map(formatChangeLog)
        .filter((version) => version !== null) as string[];

      setVersions((prev) => {
        const uniqueVersions = formattedVersions.filter((version) => !prev.includes(version));
        return [...prev, ...uniqueVersions];
      });

      setHasMore(fetchedVersions.length === limit);
    } catch (err: any) {
      setError(err.message || 'Failed to fetch versions');
    } finally {
      setLoading(false);
    }
  }, [id, limit, page, spotType]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  const loadMore = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return { versions, loading, error, hasMore, loadMore, firstIncluded };
};
