import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton, TableBody, Typography } from '@mui/material';

import { OutletAPI } from 'api/outletAPI';

import { LinkButton, StatusIndicator } from 'components/UIComponents';
import { CustomIcon } from 'components/UIComponents';
import { OutlinedButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
  InformationBlocks,
  PageSubtitle,
  OtherRecordInfo,
} from 'components/base/BaseTable';
import Breadcrumbs from 'components/base/Breadcrumbs/Breadcrumbs';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import OutletForm from 'components/forms/OutletForm';

import { ModalContext } from 'context/ModalContext';

import { ICommonRecordInfo } from 'interfaces/ICommonRecordInfo';
import { IOutletDetails } from 'interfaces/Outlet/IOutletDetails';

import { colors } from 'styles/globalStyles';

const OUTLET_FORM_MODAL_NAME = 'outletFormModal';

const OutletDetails = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { outletId } = useParams();
  const [currentOutlet, setOutlet] = useState<IOutletDetails | null>(null);

  const onEditClick = () => {
    onModalOpen(OUTLET_FORM_MODAL_NAME);
  };

  const getOutletDetails = (id: number) => {
    OutletAPI.getDetails(id).then(({ data }: { data: IOutletDetails }) => {
      setOutlet(data);
    });
  };

  useEffect(() => {
    getOutletDetails(Number(outletId!));
  }, [outletId]);

  if (!currentOutlet) return null;

  const onGroupClick = (groupId: number) => {
    window.open(`/groups/${groupId}`, '_blank', 'noreferrer');
  };

  const getYesNo = (field: any) => {
    switch (field) {
      case null:
        return null;
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return null;
    }
  };

  const switchOutletActivation = (id: number, activate: boolean) => {
    OutletAPI.switchOutletActivation(id, activate).then(({ data }) => {
      if (data === 'Successfully changed.' && currentOutlet) {
        setOutlet({ ...currentOutlet, is_active: activate });
      }
    });
  };

  const ActivateButtonText = currentOutlet.is_active ? 'Deactivate' : 'Activate';

  return (
    <PageContainer>
      <Breadcrumbs>
        <Link to="/outlets">Outlets</Link>
        <Typography>{currentOutlet.name}</Typography>
      </Breadcrumbs>
      <PageTitle>
        {currentOutlet.name}
        <StatusIndicator active={currentOutlet.is_active} />
        <OutlinedButton
          sx={{ '&:last-of-type': { marginLeft: 'auto' } }}
          onClick={() => switchOutletActivation(currentOutlet.id, !currentOutlet.is_active)}
        >
          {ActivateButtonText}
        </OutlinedButton>
      </PageTitle>
      <PageSubtitle>
        Information
        <IconButton onClick={onEditClick}>
          <CustomIcon name="edit" color={colors.red} />
        </IconButton>
      </PageSubtitle>
      <InformationBlocks>
        <FlexChild>
          <InfoBlockTitle>Media Info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Group</HeadCell>
                <BodyCell>
                  <LinkButton mode="dark" onClick={(e) => onGroupClick(currentOutlet.group_id)}>
                    {currentOutlet.company_name}
                  </LinkButton>
                </BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Name</HeadCell>
                <BodyCell>{currentOutlet.name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Media Type</HeadCell>
                <BodyCell>{currentOutlet.type}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Band</HeadCell>
                <BodyCell>{currentOutlet.band}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Ad Type</HeadCell>
                <BodyCell>{currentOutlet.ad_type}</BodyCell>
              </TableRow>
              {
                currentOutlet.type === 'Podcast' && (
                <>
                  <TableRow>
                    <HeadCell>Digitally inserted</HeadCell>
                    <BodyCell>{currentOutlet.is_digitally_inserted}</BodyCell>
                  </TableRow>
                  <TableRow>
                    <HeadCell>Read</HeadCell>
                    <BodyCell>{currentOutlet.read}</BodyCell>
                  </TableRow>
                  <TableRow>
                    <HeadCell>Pixel</HeadCell>
                    <BodyCell>{getYesNo(currentOutlet.pixel)}</BodyCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <HeadCell>Video Impressions</HeadCell>
                <BodyCell>{getYesNo(currentOutlet.includes_video_impressions)}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
          <InfoBlockTitle>Broadcast Info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Market</HeadCell>
                <BodyCell>{currentOutlet.market}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>ZIP Code</HeadCell>
                <BodyCell>{currentOutlet.zip}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <FlexChild>
          <InfoBlockTitle>Formats</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Format</HeadCell>
                <BodyCell>{currentOutlet.format_name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Secondary Format</HeadCell>
                <BodyCell>{currentOutlet.secondary_format_name}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
          <InfoBlockTitle>Comment</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <BodyCell>{currentOutlet.comment}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <OtherRecordInfo info={currentOutlet as ICommonRecordInfo} />
      </InformationBlocks>
      {
        <Modal name={OUTLET_FORM_MODAL_NAME} title="Edit Outlet" onModalClose={onModalClose}>
          <OutletForm
            onModalClose={() => onModalClose(OUTLET_FORM_MODAL_NAME)}
            successAction={() => getOutletDetails(Number(outletId!))}
            editOutletId={Number(outletId!)}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default OutletDetails;
