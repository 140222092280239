import commonSpotActions from 'api/commonSpotActions';

import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { IBulkActionData } from 'interfaces/IBulkActionData';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ILegacyForReviseTimesInfo } from 'interfaces/spots/Legacy/ILegacyForReviseTimes';
import { ILegacyForm } from 'interfaces/spots/Legacy/ILegacyForm';

import { request } from '../instance';

interface ILegacyForReviseRatesInfo {
  grossRate: string;
  netRate: string;
}

interface ILegacyForReviseSpotNumberInfo {
  spotsNumber: number | string;
}

export const LegacySpotAPI = {
  getList: async ({ page, per_page, filterOptions = {} }: IFilterParams) => {
    const response = await request({
      url: 'v1/legacy_spots',
      method: 'GET',
      params: {
        page,
        per_page,
        ...filterOptions,
      },
    });

    return response.data;
  },

  createLegacy: async ({
    outletId,
    productId,
    creativeId,
    codeId,
    startDate,
    endDate,
    startTime,
    endTime,
    spotsNumber,
    grossRate,
    netRate,
  }: ILegacyForm) => {
    try {
      const response = await request({
        url: 'v1/legacy_spots',
        method: 'POST',
        data: formData({
          outletId,
          productId,
          creativeId,
          codeId,
          startDate,
          endDate,
          startTime,
          endTime,
          spotsNumber,
          grossRate,
          netRate,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (legacyId: number) => {
    try {
      const response = await request({
        url: `v1/legacy_spots/${legacyId}`,
        method: 'GET',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateLegacy: async (id: number, values: ILegacyForm) => {
    try {
      const response = await request({
        url: `v1/legacy_spots/${id}`,
        method: 'PUT',
        data: formData(values),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateComment: async (legacyId: number, values: { comment: string }) => {
    try {
      const response = await request({
        url: `v1/legacy_spots/${legacyId}/update_comment`,
        method: 'PATCH',
        data: { data: { legacy_spot: values } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  reviseTimes: async (
    { startTime, endTime }: ILegacyForReviseTimesInfo,
    bulkData: IBulkActionData,
    filterParams = {}
  ) => {
    try {
      const response = await request({
        url: 'v1/legacy_spots/revise_times',
        method: 'PATCH',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
        data: {
          data: {
            legacy_spot: {
              start_time: startTime,
              end_time: endTime,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  reviseSpotNumber: async (
    { spotsNumber }: ILegacyForReviseSpotNumberInfo,
    bulkData: IBulkActionData,
    filterParams = {}
  ) => {
    try {
      const response = await request({
        url: 'v1/legacy_spots/revise_spot_number',
        method: 'PATCH',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
        data: {
          data: {
            legacy_spot: {
              spots_number: spotsNumber,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  reviseRates: async (
    { grossRate, netRate }: ILegacyForReviseRatesInfo,
    bulkData: IBulkActionData,
    filterParams = {}
  ) => {
    try {
      const response = await request({
        url: 'v1/legacy_spots/revise_rates',
        method: 'PATCH',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
        data: {
          data: {
            legacy_spot: {
              gross_rate: grossRate,
              net_rate: netRate,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getVersions: async (page: number, limit: number, legacyId: number) => {
    try {
      const response = await request({
        url: `v1/legacy_spots/${legacyId}/versions`,
        method: 'GET',
        params: { page, limit },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  checkForRevise: async (bulkData: IBulkActionData, filterParams = {}, actionType: string) => {
    try {
      const response = await request({
        url: 'v1/legacy_spots/check_for_revise',
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
          action_type: actionType,
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  ...commonSpotActions(SpotType.legacy),
};

const formData = (attributes: ILegacyForm) => ({
  data: {
    legacy_spot: keysToSnakeCase(attributes),
  },
});
