/* eslint-disable no-magic-numbers */
import { useState, useEffect } from 'react';
import { FormEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Checkbox } from '@mui/material';

import { LegacySpotAPI } from 'api/legacySpotAPI/index';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, DatePickerGroup, TimePickerGroup, Label } from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledCheckboxContainer as CheckboxContainer } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { useToast } from 'context/ToastContext';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { getResponseHandler } from 'helpers/forms';
import { legacyValidationSchema } from 'helpers/validation/legacyValidationSchema';

import { ModelName } from 'constants/enums/ModelName';
import { ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import { spotStatuses } from 'constants/spots';

import { IResponse } from 'interfaces/api';
import { ILegacyForm } from 'interfaces/spots/Legacy/ILegacyForm';

import { colors } from 'styles/globalStyles';

import DependentSelectsBlock from '../commonComponents/DependentSelectsBlock';
import RateFields from '../commonComponents/RateFields';
import { datesFieldStyles } from './styledComponents';

interface LegacyFormProps {
  onModalClose: () => void;
  successAction: () => void;
  editLegacyId: number;
  isPaid?: boolean;
}

const LegacyForm = ({ onModalClose, successAction, editLegacyId, isPaid = false }: LegacyFormProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { onToastOpen } = useToast();
  const [legacyFormInformation, setLegacyFormInformation] = useState<ILegacyForm>({
    outletId: '',
    productId: '',
    creativeId: '',
    codeId: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    spotsNumber: '',
    grossRate: '',
    netRate: '',
    status: '',
  });
  const [createAnother, setCreateAnother] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (editLegacyId) {
      LegacySpotAPI.getOne(editLegacyId).then(({ data: { data } }: IResponse) => {
        setLegacyFormInformation({
          outletId: data.outlet_id,
          outletName: data.outlet_name || '',
          productId: data.product_id,
          productName: data.product_name || '',
          creativeId: data.creative_id || '',
          creativeName: data.creative_name || '',
          codeId: data.code_id || '',
          codeName: data.code || '',
          startDate: data.start_date,
          endDate: data.end_date || '',
          startTime: data.start_time,
          endTime: data.end_time,
          spotsNumber: data.spots_number,
          grossRate: data.gross_rate,
          netRate: data.net_rate,
          status: data.status || '',
        });
      });
    }
  }, [editLegacyId]);

  const reloadList = (spotId: string) => {
    const currentUrl = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const existingSpotIds = searchParams.get('spotId')?.split(',') || [];

    if (!existingSpotIds.includes(spotId)) {
      existingSpotIds.push(spotId);
    }

    searchParams.set('spotId', existingSpotIds.join(','));

    const newUrl = `${currentUrl}?${searchParams.toString()}`;
    successAction();
    navigate(newUrl);
  };

  return (
    <>
      <Formik
        initialValues={legacyFormInformation}
        enableReinitialize={true}
        validationSchema={legacyValidationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(
            ModelName.LegacySpot,
            () => {
              if (!createAnother) {
                onModalClose();
              }
              if (!editLegacyId) {
                onToastOpen('SPOT_CREATED', 'Spot record has been successfully created', ToastType.success);
              }
              successAction();
            },
            actions
          );

          if (editLegacyId) {
            LegacySpotAPI.updateLegacy(editLegacyId, values)
              .then(responseHandler)
              .finally(() => setIsDisabled(false));
          } else {
            LegacySpotAPI.createLegacy(values)
              .then((response: IResponse) => {
                const createdLegacyId = response.data.data.id;
                if (!createAnother) {
                  onModalClose();
                }
                if (!editLegacyId) {
                  onToastOpen('SPOT_CREATED', 'Spot record has been successfully created', ToastType.success);
                }
                reloadList(createdLegacyId);
              })
              .finally(() => setIsDisabled(false));
          }
        }}
      >
        {({ errors, handleSubmit, dirty, values, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>LEGACY INFO</FieldGroupTitleContainer>
                <DependentSelectsBlock
                  values={values}
                  setFieldValue={setFieldValue}
                  formInformation={legacyFormInformation}
                  spotType={SpotType.legacy}
                  isPaid={isPaid}
                />
                <FieldGroupTitleContainer>AIR PERIOD</FieldGroupTitleContainer>
                <DatePickerGroup
                  label="Air Dates"
                  startProps={{
                    name: 'startDate',
                    required: true,
                    onChange: () => {
                      if (values.endDate) {
                        setFieldTouched('endDate', true);
                      }
                    },
                  }}
                  endProps={{ name: 'endDate', minDate: values.startDate }}
                  styles={datesFieldStyles}
                />
                <TimePickerGroup
                  label="Air Time"
                  startProps={{
                    name: 'startTime',
                    onChange: () => {
                      if (values.endTime) {
                        setFieldTouched('endTime', true);
                      }
                    },
                  }}
                  endProps={{ name: 'endTime', minTime: values.startTime }}
                  styles={datesFieldStyles}
                  required
                />
                <FieldGroupTitleContainer>PAYMENT INFO</FieldGroupTitleContainer>
                <Input
                  name="spotsNumber"
                  label="Spots"
                  required
                  styles={shortFieldStyles}
                  disabled={isPaid ? isPaid : [spotStatuses.dnr, spotStatuses.error].includes(values.status!)}
                />
                <RateFields
                  grossRateValue={values.grossRate}
                  netRateValue={values.netRate}
                  grossRateError={errors.grossRate}
                  netRateError={errors.netRate}
                  setFieldValue={setFieldValue}
                  isPaid={isPaid}
                />
              </DialogContent>
              <DialogActions>
                {!editLegacyId && (
                  <CheckboxContainer>
                    <Checkbox
                      checked={createAnother}
                      checkedIcon={<CheckboxChecked color={colors.red} />}
                      icon={<CheckboxUnchecked />}
                      onClick={() => setCreateAnother(!createAnother)}
                    />
                    <Label>Create Another</Label>
                  </CheckboxContainer>
                )}
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  {editLegacyId ? 'Save' : 'Add'}
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LegacyForm;
