import { cellStyles } from 'constants/spots';

import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import { colors } from 'styles/globalStyles';

import ActivityStatus from '../ActivityStatus';
import Cell from '../Cell';

import { OverflowTip } from 'components/UIComponents';

interface Props {
  spot: IPodcastSpotsTable;
}

const ActivitiesCells = ({ spot }: Props) => {
  return (
    <>
      <Cell
        cellProps={{ style: cellStyles.Podcast.testShow }}
        content={
          <>
            <ActivityStatus active={spot.is_test_show} title="Test show" activeColor={colors.darkGrey} />
            <div style={{ marginLeft: 17}}>
              <OverflowTip title={spot.is_digitally_inserted}>{spot.is_digitally_inserted}</OverflowTip>
            </div>
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles.Podcast.pixelRequired }}
        content={'\u0020'}
      />
    </>
  );
};

export default ActivitiesCells;
