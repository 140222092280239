import { FormEvent } from 'react';
import { Form, Formik } from 'formik';
import {
  OutlinedButton,
  SubmitButton,
  TextArea,
} from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotCommentValidationSchema } from 'helpers/validation/spotCommentValidationSchema';
import { ISpotRecordInfo } from 'interfaces/spots/ISpotRecordInfo';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';
import SpotRecordInfo from '../commonComponents/SpotRecordInfo';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';

interface CommentFormProps {
  onModalClose: () => void;
  successAction: () => void;
  currentSpot: IPodcastSpotsTable | ILegacySpotsTable | undefined;
  updateComment: (id: number, values: { comment: string }) => any;
  spotType: string;
}

const CommentForm = ({
  onModalClose,
  successAction,
  currentSpot,
  updateComment,
  spotType,
}: CommentFormProps) => {
  if (!currentSpot) return null;
  const initialValues = {
    comment: currentSpot.comment || '',
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={spotCommentValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await updateComment(currentSpot.id, values);
          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => {
          return (
            <Form>
              <DialogContent style={{ maxHeight: '420px'}}>
                <SpotRecordInfo info={currentSpot as ISpotRecordInfo} spotType={spotType} />
                <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                <TextArea name="comment" />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length}
                  onClick={(values: FormEvent<HTMLFormElement>) =>
                    handleSubmit(values)
                  }
                >
                  Save
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CommentForm;
