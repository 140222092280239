import { TableBody } from '@mui/material';
import { formatDate } from 'helpers/dateHelper';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
} from 'components/base/BaseTable';
import { ISpotRecordInfo } from 'interfaces/spots/ISpotRecordInfo';
import { usePodcastSpotVersions } from 'hooks/usePodcastSpotVersions';
import { CircularProgress, Typography } from '@mui/material';
import { LinkButton } from 'components/UIComponents';

const VERSIONS_UPLOAD_LIMIT = 3;

interface SpotRecordInfoProps {
  info: ISpotRecordInfo;
  spotType: string;
}

const SpotRecordInfo = ({ info, spotType }: SpotRecordInfoProps) => {
  const { versions, loading, error, hasMore, loadMore, firstIncluded } =
    usePodcastSpotVersions(info.id, VERSIONS_UPLOAD_LIMIT, spotType);

  return (
    <FlexChild>
      <InfoBlockTitle>SPOT DETAILS</InfoBlockTitle>
      <InformationTable>
        <TableBody>
          <TableRow>
            <HeadCell>Buyer</HeadCell>
            <BodyCell>{info.buyer}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created by</HeadCell>
            <BodyCell>{info.created_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created when</HeadCell>
            <BodyCell>{formatDate(info.created_at)}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated by</HeadCell>
            <BodyCell>{info.updated_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated when</HeadCell>
            <BodyCell>{formatDate(info.updated_at)}</BodyCell>
          </TableRow>
        </TableBody>
      </InformationTable>

      <InfoBlockTitle>SPOT HISTORY</InfoBlockTitle>
      {versions.map((version) => (
        <TableRow key={version}>
          <BodyCell>
            <span dangerouslySetInnerHTML={{ __html: version }} />
          </BodyCell>
        </TableRow>
      ))}

      {loading && <CircularProgress color="inherit" size={16} />}

      {error && <Typography color="error">{error}</Typography>}

      <LinkButton onClick={loadMore} disabled={!hasMore || loading || firstIncluded}>
        <div>Load More</div>
      </LinkButton>
    </FlexChild>
  );
};

export default SpotRecordInfo;
