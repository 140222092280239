import { SelectWithSearch } from 'components/UIComponents/index';

import { getCodeOptions, getCreativeOptions,
  getOutletOptionsWithSubText, getProductOptions } from 'helpers/collections';
import { useState } from 'react';

interface SelectsProps {
  outletId?: number | '';
  outletName?: string | '';
  productId?: number | '';
  productName?: string | '';
  creativeId?: number | '';
  creativeName?: string | '';
  codeId?: number | '';
  codeName?: string | '';
}

interface DependentSelectsBlockProps {
  values: SelectsProps;
  setFieldValue: (name: string, value: any) => void;
  formInformation: SelectsProps;
  spotType: string;
  isEdit?: boolean;
  isPaid?: boolean;
}

const renderTwoLevelOption = (props: any, option: any) => (
  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <span>{option.key}</span>
    {option.groupName && (
      <span style={{ fontSize: '0.85em', color: '#6e6e6e', textAlign: 'left' }}>
        {option.groupName}
        {option.contactName && `, ${option.contactName}`}
      </span>
    )}
  </li>
);

const DependentSelectsBlock = ({
  values,
  setFieldValue,
  formInformation,
  spotType,
  isEdit,
  isPaid = false,
}: DependentSelectsBlockProps) => {
  const [outletPixel, setOutletPixel] = useState<boolean | undefined>(undefined);
  const [productPixel, setProductPixel] = useState<boolean | undefined>(undefined);

  return (
    <>
      <SelectWithSearch
        name="outletId"
        label="Outlet"
        getOptionsList={(value: any) => getOutletOptionsWithSubText(value, spotType)}
        required
        renderOption={renderTwoLevelOption}
        initial={{
          key: formInformation.outletName || '',
          value: formInformation.outletId || '',
        }}
        disabled={isPaid}
        onChange={(e: React.SyntheticEvent<Element, Event>, newValue: any, value: any) => {
          if (newValue.value !== value.value) {
            if (isEdit || spotType === 'Legacy') {
              return;
            }

            const outletRead = newValue.add_attributes?.read || 'Host Read';
            setFieldValue('read', outletRead);

            const outletDigitallyInserted = newValue.add_attributes?.is_digitally_inserted || 'Baked In';
            setFieldValue('isDigitallyInserted', outletDigitallyInserted);

            const newOutletPixel = newValue.add_attributes?.pixel || false;
            setOutletPixel(newOutletPixel);

            if (productPixel === undefined) {
              return;
            }

            if(newOutletPixel && productPixel) {
              setFieldValue('isPixelRequired', 'Required');
              return;
            }

            if(productPixel) {
              setFieldValue('isPixelRequired', 'Preferred');
            } else {
              setFieldValue('isPixelRequired', 'Not Required');
            }
          }
        }}
      />
      <SelectWithSearch
        name="productId"
        label="Product"
        getOptionsList={(value: any) => getProductOptions(value)}
        required
        disabled={isPaid}
        onChange={(e: React.SyntheticEvent<Element, Event>, newValue: any, value: any) => {
          if (newValue.value !== value.value) {
            setFieldValue('creativeId', '');
            setFieldValue('codeId', '');

            if (isEdit || spotType === 'Legacy') {
              return;
            }

            const productEndorsement = newValue.add_attributes?.endorsement || 'PE Required';
            setFieldValue('endorsement', productEndorsement);

            const newProductPixel = newValue.add_attributes?.pixel || false;
            setProductPixel(newProductPixel);

            if (outletPixel === undefined) {
              return;
            }

            if(outletPixel && newProductPixel) {
              setFieldValue('isPixelRequired', 'Required');
              return;
            }

            if(newProductPixel) {
              setFieldValue('isPixelRequired', 'Preferred');
            } else {
              setFieldValue('isPixelRequired', 'Not Required');
            }
          }
        }}
        initial={{
          key: formInformation.productName || '',
          value: formInformation.productId || '',
        }}
      />
      <SelectWithSearch
        name="creativeId"
        label="Creative"
        disabled={!values.productId}
        getOptionsList={(value: any) => {
          if (!values.productId) {
            return Promise.resolve([]);
          }
          return getCreativeOptions(value, values.productId);
        }}
        onChange={(e: React.SyntheticEvent<Element, Event>, newValue: any, value: any) => {
          if (newValue.value !== value.value) {
            setFieldValue('codeId', '');
          }
        }}
        initial={{
          key: formInformation.creativeName || '',
          value: formInformation.creativeId || '',
        }}
        parentId={values.productId}
      />
      <SelectWithSearch
        name="codeId"
        label="Code"
        getOptionsList={(value: any) => {
          if (!values.creativeId) {
            return Promise.resolve([]);
          }
          return getCodeOptions(value, values.creativeId);
        }}
        disabled={!values.creativeId}
        parentId={values.creativeId}
        initial={{
          key: formInformation.codeName || '',
          value: formInformation.codeId || '',
        }}
      />
    </>
  );
};

export default DependentSelectsBlock;
